import React, { FC, ReactNode } from 'react';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { Box, Popover, Stack, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { Tabs } from '@views/Insights/types';
import {
  StyledFilterButton,
  StyledFilterIcon,
  StyledText,
} from '@views/Project/components/Filters/Filters.styled';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

export interface SelectedFilter {
  label: string;
  onDelete: () => void;
}

type Props = {
  children?: ReactNode;
  count?: number;

  selectedFilters: SelectedFilter[];
  onClearFilters: () => void;
};

const Filters: FC<Props> = ({ children, count, onClearFilters }) => {
  const {
    toolbox: { mode },
    isInsightsMode,
    insightsTab,
    isVersionsShown,
    isDashboardMode,
    isRiskRegisterMode,
  } = useProject();

  const isFiltersDisabled =
    (insightsTab !== Tabs.Default && isInsightsMode) ||
    isVersionsShown ||
    isDashboardMode ||
    isRiskRegisterMode ||
    mode === Modes.RiskManagement;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `filter`,
  });

  return (
    <Stack>
      <Box
        position="relative"
        display="flex"
        justifyContent="between"
        alignItems="center"
        width="100%"
      >
        <StyledFilterButton
          $isActive={popupState.isOpen}
          $isDisabled={isFiltersDisabled}
          disabled={isFiltersDisabled}
          {...bindTrigger(popupState)}
        >
          <StyledFilterIcon
            fill={themePalette.grey[isFiltersDisabled ? 700 : 900]}
          />
          {!!count && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                position: 'absolute',
                top: '0',
                right: '-50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: themePalette.primary.main,
                width: '20px',
                height: '20px',
                borderRadius: '50px',
              }}
            >
              <Typography variant="body2" color="grey.1000">
                {count}
              </Typography>
            </Stack>
          )}
        </StyledFilterButton>

        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{ paper: { sx: { width: 220, mt: 3 } } }}
        >
          <Typography variant="h4" sx={{ p: '8px 12px' }}>
            Filters
          </Typography>

          <Stack maxHeight={350} overflow="auto">
            {children}
          </Stack>

          <StyledText
            variant="body1"
            onClick={count ? onClearFilters : undefined}
            $disabled={!count}
          >
            Clear filters
          </StyledText>
        </Popover>
      </Box>
    </Stack>
  );
};

export default Filters;
