import { FieldValues, UseFormReturn } from 'react-hook-form';
import { XYPosition } from 'reactflow';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import useEntityVisibilityCheck from '@components/EntityDrawers/hooks/useEntityVisibilityCheck';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { AssetsSubLayerTypes } from '@constants/canvas/general';
import { CiaSubLayers, SubLayerTypes } from '@constants/canvas/layers';
import { DateFormats } from '@constants/dates';
import { ISelectOption, WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useResetViewportOnNodeDrawer from '@hooks/canvas/useResetViewportOnNodeDrawer';
import { useAppDispatch } from '@hooks/store';
import useAssets from '@hooks/useAssets';
import useCurrentAsset from '@hooks/useCurrentAsset';
import useProjectId from '@hooks/useProjectId';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import { nodesApi, useCreateProjectNodeMutation } from '@store/services/nodes';
import {
  AssessmentMetaData,
  ICreateNodeRequest,
  IUpdateNodeRequest,
  NodeDTO,
} from '@store/services/nodes/types';
import { QueryTags } from '@store/services/tags';
import { add, prepareCia } from '@utils/drawers';
import {
  getNodeTypeTypeByForSubLayer,
  parseErrorResponse,
} from '@utils/helpers';
import dayjs from 'dayjs';

export type NodeSaveOptions<T extends FieldValues, M> = {
  editMode?: boolean;
  form: UseFormReturn<T>;
  transformMetaDataToRequestBody: (
    values: T,
    context: { subLayer: SubLayerTypes; asset: NodeDTO<AssessmentMetaData> },
  ) => Record<keyof M, any>;
  onUpdate: (body: IUpdateNodeRequest) => Promise<void>;
};

const useNodeSave = <T extends FieldValues, M>({
  form: { getValues, setError },
  editMode,
  transformMetaDataToRequestBody,
  onUpdate,
}: NodeSaveOptions<T, M>) => {
  const projectId = useProjectId(true);
  const resetViewPort = useResetViewportOnNodeDrawer();

  const {
    hideDrawer,
    toolbox: { mode },
    setHasChanges,
  } = useProject();

  const { showToast } = useToast();
  const activeNode = useSourceNode();
  const dispatch = useAppDispatch();
  const { isNewAssetMode, resetNewAssetMode, selectAsset, selectedAssetId } =
    useAssets();
  const { dto: asset } = useCurrentAsset();

  const { checkIsEntityBecameHidden } = useEntityVisibilityCheck();
  const saveResponseToRedux = useSaveEntitiesResponse();

  const [createNode, { isLoading: isCreating }] =
    useCreateProjectNodeMutation();

  const prepareDraftBody = (
    id: string,
    position: XYPosition,
  ): IUpdateNodeRequest => {
    const values = getValues();

    const nodeBody: IUpdateNodeRequest = {
      nodeId: id,
      assetId: selectedAssetId || undefined,

      mode,

      name: values[CommonKeys.Name] || '',
      requirement_owner: values[CommonKeys.Owner] || null,

      domain_id: [undefined, WideDomainKey].includes(values[CommonKeys.Domain])
        ? null
        : values[CommonKeys.Domain],

      ...(values[CommonKeys.ValidationStatus]
        ? {
            valid_status: values[CommonKeys.ValidationStatus],
          }
        : {}),

      reviewed_at: values[CommonKeys.DateReviewed]?.isValid()
        ? values[CommonKeys.DateReviewed].format(DateFormats[2])
        : null,

      meta_data:
        transformMetaDataToRequestBody(getValues(), {
          subLayer: (activeNode?.parentNode ?? '') as SubLayerTypes,
          asset,
        }) ?? {},

      y: position.y,
      x: position.x,

      qnous_ref:
        values[CommonKeys.QnousRef]?.map(({ value }: ISelectOption) => value) ??
        [],

      nist:
        values[CommonKeys.NistRef]?.map(({ value }: ISelectOption) => value) ??
        [],

      description: values[CommonKeys.Description] || null,

      ...(selectedAssetId && { asset_id: selectedAssetId }),

      ...(values[AssetKeys.BusinessAttributes]
        ? {
            businessAttributes: (
              (values[AssetKeys.BusinessAttributes] ?? []) as ISelectOption[]
            ).map(({ value, label }: ISelectOption) => ({
              id: value as string,
              name: label,
            })),
          }
        : {}),

      ...add(CiaSubLayers.includes(activeNode?.parentNode as any), {
        cia: prepareCia({
          assurance_level: values[CommonKeys.Assurance],
          coverage: values[CommonKeys.Coverage],
          applicability: values[CommonKeys.Applicability],
        }),
      }),
    };

    return nodeBody;
  };

  const handleCreateNode = async (
    nodeBody: IUpdateNodeRequest,
    subLayerId: SubLayerTypes,
  ) => {
    const type = getNodeTypeTypeByForSubLayer(subLayerId);
    const isAssetSubLayer = AssetsSubLayerTypes.includes(subLayerId);

    const createNodeBody: ICreateNodeRequest = {
      ...nodeBody,
      projectId,
      type,
      ...(isAssetSubLayer && selectedAssetId
        ? { parent_asset: selectedAssetId }
        : {}),
    };

    const response = await createNode(createNodeBody).unwrap();

    // Looking for created node (sorted nodes by updated_at to get the newest one first)
    const createdNode = response.nodes
      .toSorted((a: NodeDTO, b: NodeDTO) => {
        return dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? 1 : -1;
      })
      .find(({ name }: NodeDTO) => name === nodeBody.name);

    if (isNewAssetMode) {
      dispatch(nodesApi.util.invalidateTags([QueryTags.AssetsList]));
      selectAsset(createdNode?.id ?? '');
      resetNewAssetMode();
    }

    if (createdNode) {
      checkIsEntityBecameHidden(createdNode);
    }

    saveResponseToRedux(response);
  };

  const handleResetViewport = () => resetViewPort();

  const handleSave = async () => {
    try {
      if (!activeNode?.parentNode) {
        throw new Error('Active node not found or has no parent node');
      }

      const { id, position, parentNode } = activeNode;

      const nodeBody = prepareDraftBody(id, position);

      if (editMode) {
        await onUpdate(nodeBody);

        setHasChanges(false);
        handleResetViewport();

        return;
      }

      await handleCreateNode(nodeBody, parentNode as SubLayerTypes);

      setHasChanges(false);
      hideDrawer();
      handleResetViewport();
    } catch (error: any) {
      const message = error.message || parseErrorResponse(error);

      if (error.data?.field && error.data.field in getValues()) {
        return setError(error.data.field, { message });
      }

      showToast(message, 'error');
    }
  };

  return {
    handleSave,
    isLoading: isCreating,
  };
};

export default useNodeSave;
