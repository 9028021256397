import { Modes } from '@constants/canvas/layers';
import { Currency } from '@constants/entities/currency';
import { InsightKeys } from '@constants/entities/insights';
import { ProjectStatus } from '@constants/entities/project';
import { Tier } from '@constants/entities/tiers';
import {
  EnterpriseRolesType,
  UserRoles,
  UserStatus,
} from '@constants/entities/user';
import { UserItem } from '@constants/forms/users';
import { IGetUsersParams, IUser } from '@store/services/admin/types';
import { NodeDTO } from '@store/services/nodes/types';
import { IOrganization } from '@store/services/organizations/types';

export interface IGetProjectsParams {
  organizationId?: string;
  id?: string;
  name?: string;
  statuses?: ProjectStatus[];
  industries?: string[];
  assignee?: string[];
  locations?: string[];
  noSpinner?: boolean;
  page?: number;
  pageSize?: number;
}

export interface IProjectUser {
  id: string;
  email: string;
  full_name: string;
  status: UserStatus;
  organization_id: string;
  created_at: string;
  updated_at: string;
  last_login_at: string;
  role: EnterpriseRolesType;
  phone: string;
  avatar: string;
  business_unit: string;
}

export interface IDomain {
  parent: string;
  id: string;
  name: string;
  description: string;
  authority: string;
  parentId: string;
  level: number;
  projectId: string;
  num: number;
}

export interface IRange {
  rangeMin: number;
  rangeMax: number;
}

export interface ICriticalityLevel extends IRange {
  name: string;
}

export interface IAppetitTolerance extends IRange {}

export interface ISensitive {
  name: string;
  children: string[];
}

export interface ISettings {
  currency: Currency;
  risk_assessment: {
    likelihood: string[];
    impact: string[];
    matrix: number[][];
  };
  sensitive: ISensitive[];
  asset_criticality_level: string[];
  risk_action: string[];
  risk_taxonomy: string[];
  risk_criticality_level: ICriticalityLevel[];
  risk_appetit_tolerance: IAppetitTolerance[];
}

export enum ProjectVisibility {
  Public = 'public',
  Private = 'private',
}

export interface Industry {
  id: string;
  name: string;
}

export interface ISubscription {
  [UserRoles.ClientModeller]: number;
  [UserRoles.Contributor]: number;
  [UserRoles.Viewer]: number;
  modes: Modes[];
  insights: InsightKeys[];
}

export const ProjectsPerPage = 12;

export interface CIASettingsCriteriaType {
  name: string;
  question?: string;
  answers: string[];
}
export interface CIASettingsTopicType {
  topic?: string;
  criterias: CIASettingsCriteriaType[];
}

export interface CIASettingsType {
  levels_based_on_questionnaire: boolean;
  assurance_level: CIASettingsTopicType;
  coverage: CIASettingsTopicType;
  applicability: CIASettingsTopicType;
}

export enum QuestionnaireStatus {
  Draft = 'draft',
  Active = 'active',
}

export type ProjectQuestionnaire = {
  id: string;
  status: QuestionnaireStatus;
  cia_criteria: CIASettingsType;
};

export interface IProject {
  id: string;
  organization_id: string;
  name: string;
  description: string;
  logo: string;
  industry: Industry;
  users: IProjectUser[];
  contact_user: IProjectUser;
  domains: IDomain[];
  source?: IProject;

  status: ProjectStatus;
  questionnaire: ProjectQuestionnaire;
  settingsData: ISettings;
  organization: IOrganization;

  sponsor_name: string;
  sponsor_email: string;

  parent_id: string;
  source_id: string;

  author: IProjectUser;
  visibility: ProjectVisibility;
  author_id: string;
  created_at: string;
  updated_at: string;

  subscription: ISubscription;
}

export interface IDesignCompletenessItem {
  id: string;
  missing: string[];
  complete: string[];
  incomplete: string[];
}

export interface ICompletenessInsightResponse {
  designCompleteness: {
    items: IDesignCompletenessItem[];
    total: number;
    mapped: number;
  };

  operationalCompleteness: {
    items: Record<string, string[]>;
    totalOperational: number;
  };
}

export interface IDesignCompleteness {
  items: IDesignCompletenessItem[];
  percentage: number;
}

export interface IOperationalCompleteness {
  items: Record<string, string[]>;
  percentage: number;
}

export interface ICompletenessInsight {
  designCompleteness: IDesignCompleteness;
  operationalCompleteness: IOperationalCompleteness;
}

export interface IInsightParams {
  projectId: string;

  domainIds?: string[];
  validStatus?: string;
  reviewRequired?: boolean;
}

export interface IProjectUpdateRequest {
  id: string;
  name?: string;
  status?: string;
  description?: string;

  settingsData?: Partial<ISettings>;

  sponsor_name?: string | null;
  sponsor_email?: string | null;
  domains?: DomainsPayload;

  // helpers
  userInvalidation?: boolean;
}

export enum ImpactType {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export interface IKeyControlParentItem {
  id: string;
  name: string;
  type: string;
  items: NodeDTO[];
}

export interface IKeyControlListProps {
  impact: ImpactType;
  nodes: IKeyControlParentItem[];
}

export type KeyControlsType = {
  [key in ImpactType]: IKeyControlParentItem[];
};

export interface IKeyControlsInsight {
  keyControls: KeyControlsType;
}

export interface IProjectGlossary {
  industry: Industry[];
  tiers: Tier[];
  currency: Currency[];
  roles: UserRoles[];
  locations: string[];
  usedLocations: string[];
  links: {
    policy: string;
    terms: string;
    user_manual: string;
  };
}

export interface IGetProjectUsersParams extends IGetUsersParams {
  project_id: string;
  noSpinner?: boolean;
}

export interface ISubDomainPayload {
  id?: string;
  name: string;
  num: number;
  authority: string;
}

export interface IDomainPayload extends ISubDomainPayload {
  children: ISubDomainPayload[];
}

export type DomainsPayload = IDomainPayload[];

export interface ICreateProjectRequest {
  name: string;
  description: string;
  sponsor_name: string;
  sponsor_email: string;
  consultants: string[];

  organization: Omit<IOrganization, 'id'>;

  users: UserItem[];

  settingsData: Pick<ISettings, 'currency'>;

  domains: DomainsPayload;
}

export interface IProjectRestoreRequest {
  id: string;
  action: string;
  tierId?: string;
}

export interface IUserBody {
  full_name: string;
  email: string;
  role: EnterpriseRolesType;
  business_unit: string;
}

export interface IAddUsersToProjectRequest {
  project_id: string;
  users: IUserBody[];
}

export type IProjectQuestionnaireLog = {
  id: string;
  meta_data: {
    size: number;
    filename: string;
  };
  author: IUser;
  created_at: string;
};
