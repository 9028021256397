export enum QueryTags {
  User = 'User',
  Users = 'Users',
  Projects = 'Projects',
  Project = 'Project',
  Organization = 'Organization',
  ProjectDomains = 'ProjectDomains',
  ProjectStats = 'ProjectStats',
  ProjectNodes = 'ProjectNodes',
  ProjectUsers = 'ProjectUsers',
  AssetNodes = 'AssetNodes',
  AssetCompatibleNodes = 'AssetCompatibleNodes',
  ProjectScenarios = 'ProjectScenarios',
  ProjectVersions = 'ProjectVersions',
  QnousRefLogs = 'QnousRefLogs',
  CompletenessInsight = 'CompletenessInsight',
  KeyControlsInsight = 'KeyControlsInsight',
  AssetsList = 'AssetsList',
  ServiceTiers = 'ServiceTiers',
  Tier = 'Tier',
  Questions = 'Questions',
  ControlsDashboard = 'ControlsDashboard',
  BusinessAlignmentDashboard = 'BusinessAlignmentDashboard',
  RiskDashboard = 'RiskDashboard',
  ProjectsGlossary = 'ProjectsGlossary',
  QuestionnaireLogs = 'QuestionnaireLogs',
}
