import Box from '@mui/material/Box';
import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

export const UploadStatusBoxStyled = styled(Box)`
  margin-top: 24px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${themePalette.grey[500]};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ErrorBoxStyled = styled('div')`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: ${themePalette.grey[400]};
`;
