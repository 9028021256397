import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CommonKeys,
  CoreFormKeys,
} from '@components/EntityDrawers/constants/keys';
import { ComponentKeys } from '@components/EntityDrawers/drawers/Component/keys';
import { ShapeType } from '@constants/types';
import { NameLinksYup } from '@constants/validation';
import { NameLink } from '@store/services/nodes/types';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import { withId } from '@utils/helpers';
import * as yup from 'yup';

export type ComponentFormValues = yup.InferType<typeof validationSchema> & {
  [ComponentKeys.AuditFinding]: NameLink[];
};

export const ComponentsFields = [
  ...CoreFormKeys,
  CommonKeys.Description,
  CommonKeys.OperationalStatus,
  CommonKeys.TimelineStartDate,
  CommonKeys.TimelineEndDate,
  CommonKeys.DesignEffectiveness,
  CommonKeys.OperationalEffectiveness,
  CommonKeys.OperationalModel,
  CommonKeys.Assurance,
  CommonKeys.Coverage,
  CommonKeys.Applicability,
] as const;

export const validationSchema = generateSchema<
  ShapeType<typeof ComponentsFields>
>({
  ...getYups(...ComponentsFields),
  [ComponentKeys.AuditFinding]: NameLinksYup(),
});

export const defaultValues: ComponentFormValues = {
  ...getDefs(...ComponentsFields),
  [ComponentKeys.AuditFinding]: [withId(NameLinkObject)],
};
