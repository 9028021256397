import { useSelector } from 'react-redux';
import { UserRoles } from '@constants/entities/user';
// eslint-disable-next-line import/no-unresolved
import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetMeQuery } from '@store/services/auth';
import { IUser } from '@store/services/auth/types';
import { selectAccessToken } from '@store/slices/authSlice';
import { isQmsRole } from '@utils/helpers';

const useUser = (): [
  IUser & { isQmsUser: boolean },
  Omit<UseQueryHookResult<any, any>, 'data'>,
] => {
  const accessToken = useSelector(selectAccessToken);

  const { data, ...options } = useGetMeQuery(
    accessToken ? undefined : skipToken,
  );

  const isQmsUser = isQmsRole(data?.role as UserRoles);

  return [
    { ...(data ?? {}), isQmsUser } as IUser & {
      isQmsUser: boolean;
    },
    options,
  ];
};

export default useUser;
