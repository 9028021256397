import React, { FC, memo, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTooltip } from '@components/CustomTooltip';
import ScenarioSwitch from '@components/Headers/core/ScenarioSwitch';
import SelectorButton from '@components/selectors/core/SelectorButton';
import FilterSelector from '@components/tables/core/FilterSelector';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useProjectFilters } from '@context/ProjectFilters/ProjectFiltersProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import useChangeFilterHandler from '@hooks/useChangeFilterHandler';
import useProjectId from '@hooks/useProjectId';
import useToggle from '@hooks/useToggle';
import { Divider, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { ROUTES } from '@router/routes';
import { useGetProjectDomainsQuery } from '@store/services/projects';
import { themePalette } from '@theme/muiTheme';
import {
  DashboardIcon,
  GlobalIcon,
  LightIcon,
  ModelingIcon,
  RiskRegisterIcon,
} from '@utils/iconsDefs';
import { Tabs } from '@views/Insights/types';
import { AdditionalSettings } from '@views/Project/components/AdditionalSettings/AdditionalSettings';
import Filters from '@views/Project/components/Filters/Filters';
import ScopeFilterModal from '@views/Project/components/ScopeFilterModal/ScopeFilterModal';
import CiaFilters from '@views/Project/components/Toolbox/CiaFilters/CiaFilters';
import ModesDropdown from '@views/Project/components/Toolbox/ModesDropdown';
import useDomainsFilter from '@views/Project/hooks/useDomainsFilter';
import {
  LinksWrapper,
  NavLinkContent,
  StyledNavLink,
} from '@views/Project/Project.styled';

const Toolbox: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = useProjectId();
  const { data: domains = [] } = useGetProjectDomainsQuery(id ?? skipToken);

  const changeFilterHandler = useChangeFilterHandler();

  const {
    toolbox: { mode },
    handleCloseDrawer,
    insightsTab,
    isInsightsMode,
    isVersionsShown,
    isDashboardMode,
  } = useProject();

  const {
    validationStatus,
    reviewStatus,
    validationStatusOptions,
    reviewStatusOptions,
    setReviewStatus,
    setValidationStatus,
    selectedFilters,
    handleClearAllFilters,
  } = useProjectFilters();

  const [isScopeOpen, scopeControls] = useToggle();
  const { ScopeName, ScopeCount } = useDomainsFilter(domains);
  const { saveCurrentZoom } = useCanvasZoom();

  const isScopeFilterDisabled =
    (insightsTab !== Tabs.Default && isInsightsMode) ||
    isVersionsShown ||
    isDashboardMode ||
    mode === Modes.RiskManagement;

  const isProjectLinksDisabled = isVersionsShown;

  const handleSetReview = changeFilterHandler({
    isNodeVisible: (_, node) => node?.data.dto.review_required,
    onSetValues: setReviewStatus,
  });

  const handleSetStatus = changeFilterHandler({
    isNodeVisible: (statuses, node) =>
      statuses.includes(node?.data.dto.valid_status),
    onSetValues: setValidationStatus,
  });

  const toolboxLinks = [
    {
      label: 'Dashboard',
      Icon: DashboardIcon,
      to: ROUTES.PROJECT.DASHBOARD.replace(':id', id),
    },
    {
      label: 'Model',
      Icon: ModelingIcon,
      to: ROUTES.PROJECT.MODEL.replace(':id', id),
    },
    {
      label: 'Insights',
      Icon: LightIcon,
      to: ROUTES.PROJECT.INSIGHTS.replace(':id', id),
    },
  ];

  const RiskRegisterLink = {
    label: 'Risk register',
    Icon: RiskRegisterIcon,
    to: ROUTES.PROJECT.RISKS.replace(':id', id),
    type: 'riskRegister',
  };

  const toolboxLinksBasedOnMode =
    mode === Modes.RiskManagement
      ? toolboxLinks.map((link) =>
          link.label === 'Insights' ? RiskRegisterLink : link,
        )
      : [...toolboxLinks];

  const handleGoToView = async (
    e: MouseEvent<HTMLAnchorElement>,
    to: string,
  ) => {
    e.preventDefault();

    await handleCloseDrawer();

    saveCurrentZoom();

    navigate(to, {
      state: {
        fromInsights: location?.pathname?.includes('insights'),
      },
    });
  };

  return (
    <>
      <ScopeFilterModal
        open={isScopeOpen}
        onClose={scopeControls.off}
        domains={domains}
      />

      <Stack
        p={6}
        direction="row"
        justifyContent="space-between"
        borderBottom={`1px solid ${themePalette.grey[500]}`}
      >
        <Stack gap={4} direction="row" alignItems="center">
          <ModesDropdown />

          <CustomTooltip
            icon={<GlobalIcon />}
            title="Scope"
            subtitle="Explore your model from various perspectives using scope filtering."
            placement="bottom-start"
          >
            <div>
              <SelectorButton
                title={ScopeName}
                disabled={isScopeFilterDisabled}
                sx={{
                  width: 240,
                  border: `1px solid ${
                    themePalette.grey[isScopeFilterDisabled ? 500 : 700]
                  }`,
                }}
                onClick={scopeControls.on}
                count={ScopeCount}
              />
            </div>
          </CustomTooltip>

          <Divider orientation="vertical" flexItem />

          <LinksWrapper $disabled={isProjectLinksDisabled}>
            {toolboxLinksBasedOnMode.map(({ label, Icon, to }) => {
              return (
                <StyledNavLink
                  to={to}
                  key={label}
                  onClick={(e) => handleGoToView(e, to)}
                >
                  {({ isActive }) => {
                    return (
                      <NavLinkContent $isActive={isActive}>
                        <Icon
                          fill={themePalette.grey[isActive ? 1000 : 800]}
                          width={21}
                          height={21}
                        />
                        <Typography
                          variant="h3"
                          color={isActive ? 'grey.1000' : 'grey.800'}
                          sx={{ ml: 1 }}
                        >
                          {label}
                        </Typography>
                      </NavLinkContent>
                    );
                  }}
                </StyledNavLink>
              );
            })}
          </LinksWrapper>

          <Divider orientation="vertical" flexItem />

          <Filters
            selectedFilters={selectedFilters}
            count={isDashboardMode ? 0 : selectedFilters.length}
            onClearFilters={handleClearAllFilters}
          >
            <FilterSelector
              title="By validation status"
              values={validationStatus}
              onChange={
                handleSetStatus as (values: (string | number)[]) => void
              }
              options={validationStatusOptions}
              expandByDefault
            />

            <FilterSelector
              title="By review status"
              values={reviewStatus}
              onChange={
                handleSetReview as (values: (string | number)[]) => void
              }
              options={reviewStatusOptions}
              expandByDefault
            />

            <CiaFilters />
          </Filters>

          <AdditionalSettings />
        </Stack>

        <ScenarioSwitch />
      </Stack>
    </>
  );
};

export default memo(Toolbox);
