import React from 'react';
import CriteriaBlock from '@components/EntityDrawers/components/CiaInputsGroup/CriteriaBlock';
import useCiaData from '@components/EntityDrawers/components/CiaInputsGroup/hooks/useCiaData';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { Skeleton, Stack } from '@mui/material';

const CiaInputsGroup = () => {
  const { isCiaActivated, questionnaire, isLoading } = useCiaData();

  if (isLoading) {
    return (
      <Stack gap={4}>
        <Skeleton />
      </Stack>
    );
  }

  if (!isCiaActivated || !questionnaire) return null;

  return (
    <>
      <CriteriaBlock
        title="Assurance levels"
        fields={questionnaire.cia_criteria.assurance_level.criterias}
        criteriaKey={CommonKeys.Assurance}
      />

      <CriteriaBlock
        title="Coverage"
        fields={questionnaire.cia_criteria.coverage.criterias}
        criteriaKey={CommonKeys.Coverage}
      />

      <CriteriaBlock
        title="Applicability"
        fields={questionnaire.cia_criteria.applicability.criterias}
        criteriaKey={CommonKeys.Applicability}
      />
    </>
  );
};

export default CiaInputsGroup;
