import {
  NodeDTOTypes,
  ProjectModesDTOTypes,
} from '@store/services/nodes/types';

export enum Modes {
  BusinessAlignment = 'business-attribute',
  Controls = 'control',
  RiskManagement = 'risk-management',
}

export const DTOModesToInnerModesMap = {
  [ProjectModesDTOTypes.BusinessAlignment]: Modes.BusinessAlignment,
  [ProjectModesDTOTypes.Controls]: Modes.Controls,
  [ProjectModesDTOTypes.RiskManagement]: Modes.RiskManagement,
};

export enum LayerTypes {
  Contextual = 'contextual',
  Conceptual = 'conceptual',
  Logical = 'logical',
  Physical = 'physical',
  Component = 'component',
  Initiatives = 'initiatives',
  RemediationAction = 'remediation-action',
  AssessmentScope = 'assessment',
  SensitiveElements = 'sensitive',
  AssociatedRisks = 'risks',
}

export const ModeLayers = {
  [Modes.BusinessAlignment]: [
    LayerTypes.Contextual,
    LayerTypes.Conceptual,
    LayerTypes.Logical,
  ],
  [Modes.Controls]: [
    LayerTypes.Conceptual,
    LayerTypes.Logical,
    LayerTypes.Physical,
    LayerTypes.Component,
    LayerTypes.Initiatives,
  ],
  [Modes.RiskManagement]: [
    LayerTypes.AssessmentScope,
    LayerTypes.SensitiveElements,
    LayerTypes.AssociatedRisks,
    LayerTypes.Physical,
    LayerTypes.Initiatives,
  ],
} as const;

export const ReadableLayerTitles = {
  [LayerTypes.Contextual]: 'Contextual',
  [LayerTypes.Conceptual]: 'Conceptual',
  [LayerTypes.Logical]: 'Logical',
  [LayerTypes.Physical]: 'Physical',
  [LayerTypes.Component]: 'Component',
  [LayerTypes.Initiatives]: 'Initiatives',
  [LayerTypes.RemediationAction]: 'Remediation Actions',
  [LayerTypes.AssessmentScope]: 'Assessment Scope',
  [LayerTypes.SensitiveElements]: 'Sensitive Elements',
  [LayerTypes.AssociatedRisks]: 'Associated Risks',
};

export enum ContextualSubLayerTypes {
  StrategicPriorities = 'strategic-priorities',
  StrategicEnablers = 'strategic-enablers',
}

export enum ConceptualSubLayerTypes {
  BusinessAttributes = 'business-attributes',
  SecurityAttributes = 'security-attributes',
}

export enum LogicalSubLayerTypes {
  Services = 'services',
}

export enum PhysicalSubLayerTypes {
  Capabilities = 'capabilities',
  Functions = 'functions',
}

export enum ComponentSubLayerTypes {
  Technologies = 'technologies',
}

export enum InitiativesSubLayerTypes {
  ChangeInitiatives = 'change-initiatives',
}

export enum ChangeInitiativesSubLayerTypes {
  RemediationAction = 'Remediation action',
}

export enum AssessmentScopeSubLayerTypes {
  Asset = 'assets',
}

export enum SensitiveElementsSubLayerTypes {
  AssetCharacteristics = 'asset-characteristics',
}

export enum AssociatedRisksSubLayerTypes {
  Risks = 'associated-risks',
}

export const CiaSubLayers = [
  LogicalSubLayerTypes.Services,
  PhysicalSubLayerTypes.Functions,
  PhysicalSubLayerTypes.Capabilities,
  ComponentSubLayerTypes.Technologies,
];

export const subLayerParent = {
  [ContextualSubLayerTypes.StrategicPriorities]: LayerTypes.Contextual,
  [ContextualSubLayerTypes.StrategicEnablers]: LayerTypes.Contextual,
  [ConceptualSubLayerTypes.BusinessAttributes]: LayerTypes.Conceptual,
  [ConceptualSubLayerTypes.SecurityAttributes]: LayerTypes.Conceptual,
  [LogicalSubLayerTypes.Services]: LayerTypes.Logical,
  [PhysicalSubLayerTypes.Capabilities]: LayerTypes.Physical,
  [PhysicalSubLayerTypes.Functions]: LayerTypes.Physical,
  [ComponentSubLayerTypes.Technologies]: LayerTypes.Component,
  [InitiativesSubLayerTypes.ChangeInitiatives]: LayerTypes.Initiatives,
  [AssessmentScopeSubLayerTypes.Asset]: LayerTypes.AssessmentScope,
  [SensitiveElementsSubLayerTypes.AssetCharacteristics]:
    LayerTypes.SensitiveElements,
  [AssociatedRisksSubLayerTypes.Risks]: LayerTypes.AssociatedRisks,
  [ChangeInitiativesSubLayerTypes.RemediationAction]:
    LayerTypes.RemediationAction,
};

export type SubLayerTypes =
  | ContextualSubLayerTypes
  | ConceptualSubLayerTypes
  | LogicalSubLayerTypes
  | PhysicalSubLayerTypes
  | ComponentSubLayerTypes
  | InitiativesSubLayerTypes
  | AssessmentScopeSubLayerTypes
  | SensitiveElementsSubLayerTypes
  | AssociatedRisksSubLayerTypes
  | ChangeInitiativesSubLayerTypes;

export type BusinessAlignmentSubLayer =
  | ContextualSubLayerTypes
  | ConceptualSubLayerTypes
  | LogicalSubLayerTypes;

export type ControlsSubLayer =
  | ConceptualSubLayerTypes
  | LogicalSubLayerTypes
  | PhysicalSubLayerTypes
  | ComponentSubLayerTypes
  | InitiativesSubLayerTypes;

export type RiskManagementSubLayer =
  | AssessmentScopeSubLayerTypes
  | SensitiveElementsSubLayerTypes
  | AssociatedRisksSubLayerTypes
  | ChangeInitiativesSubLayerTypes;

export const ReadableSubLayerTitles = {
  [ContextualSubLayerTypes.StrategicPriorities]: 'Strategic priorities',
  [ContextualSubLayerTypes.StrategicEnablers]: 'Strategic enablers',
  [ConceptualSubLayerTypes.BusinessAttributes]: 'Business attributes',
  [ConceptualSubLayerTypes.SecurityAttributes]: 'Security attributes',
  [LogicalSubLayerTypes.Services]: 'Services',
  [PhysicalSubLayerTypes.Capabilities]: 'Capabilities',
  [PhysicalSubLayerTypes.Functions]: 'Functions',
  [ComponentSubLayerTypes.Technologies]: 'Technologies',
  [InitiativesSubLayerTypes.ChangeInitiatives]: 'Change initiatives',
  [ChangeInitiativesSubLayerTypes.RemediationAction]: 'Remediation actions',
  [AssessmentScopeSubLayerTypes.Asset]: 'Asset',
  [SensitiveElementsSubLayerTypes.AssetCharacteristics]:
    'Asset characteristics',
  [AssociatedRisksSubLayerTypes.Risks]: 'Identified risks',
};

export const ReadableNodeTypes: Record<NodeDTOTypes, string> = {
  [NodeDTOTypes.StrategicPriority]: 'strategic priority',
  [NodeDTOTypes.StrategicEnabler]: 'Strategic enabler',
  [NodeDTOTypes.BusinessAttribute]: 'business attributes',
  [NodeDTOTypes.SecurityAttribute]: 'security attributes',
  [NodeDTOTypes.Service]: 'services',
  [NodeDTOTypes.Capabilities]: 'capabilities',
  [NodeDTOTypes.Functions]: 'functions',
  [NodeDTOTypes.Technologies]: 'technologies',
  [NodeDTOTypes.Initiatives]: 'initiatives',
  [NodeDTOTypes.Characteristic]: 'characteristics',
  [NodeDTOTypes.Asset]: 'assets',
  [NodeDTOTypes.EvaluatedRisk]: 'risks',
};

export const DTOModesToProjectModes: Record<ProjectModesDTOTypes, Modes> = {
  [ProjectModesDTOTypes.BusinessAlignment]: Modes.BusinessAlignment,
  [ProjectModesDTOTypes.Controls]: Modes.Controls,
  [ProjectModesDTOTypes.RiskManagement]: Modes.RiskManagement,
};

export const LayerSubLayers: Record<
  LayerTypes,
  (
    | ContextualSubLayerTypes
    | ConceptualSubLayerTypes
    | LogicalSubLayerTypes
    | PhysicalSubLayerTypes
    | ComponentSubLayerTypes
    | InitiativesSubLayerTypes
    | AssessmentScopeSubLayerTypes
    | SensitiveElementsSubLayerTypes
    | AssociatedRisksSubLayerTypes
    | ChangeInitiativesSubLayerTypes
  )[]
> = {
  [LayerTypes.Contextual]: [
    ContextualSubLayerTypes.StrategicPriorities,
    ContextualSubLayerTypes.StrategicEnablers,
  ],
  [LayerTypes.Conceptual]: [
    ConceptualSubLayerTypes.BusinessAttributes,
    ConceptualSubLayerTypes.SecurityAttributes,
  ],
  [LayerTypes.Logical]: [LogicalSubLayerTypes.Services],
  [LayerTypes.Physical]: [
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
  ],
  [LayerTypes.Component]: [ComponentSubLayerTypes.Technologies],
  [LayerTypes.Initiatives]: [InitiativesSubLayerTypes.ChangeInitiatives],
  [LayerTypes.RemediationAction]: [
    ChangeInitiativesSubLayerTypes.RemediationAction,
  ],
  [LayerTypes.AssessmentScope]: [AssessmentScopeSubLayerTypes.Asset],
  [LayerTypes.SensitiveElements]: [
    SensitiveElementsSubLayerTypes.AssetCharacteristics,
  ],
  [LayerTypes.AssociatedRisks]: [AssociatedRisksSubLayerTypes.Risks],
};

export enum EntityValidationStatus {
  Validated = 'validated',
  Draft = 'draft',
}

export const ReadableEntityValidationStatus = {
  [EntityValidationStatus.Validated]: 'Validated',
  [EntityValidationStatus.Draft]: 'Draft',
};

export enum EntityReviewStatus {
  ReviewRequired = 'required-review',
}

export const ReadableEntityReviewStatus = {
  [EntityReviewStatus.ReviewRequired]: 'Required review',
};

export enum OperationalStatus {
  Live = 'Live',
  InDevelopment = 'In Development',
  Sunset = 'Sunset',
}

export enum OperationalModel {
  InHouse = 'In-house',
  Outsourced = 'Outsourced',
  Hybrid = 'Hybrid',
}

export enum AnswerTypes {
  Yes = 'Yes',
  No = 'No',
}

export enum InitiativeTypes {
  StrategicInitiative = 'Strategic initiative',
  RemediationAction = 'Remediation action',
  AuditFinding = 'Audit finding',
  Operational = 'Operational',
}

export enum InitiativeProgressStatus {
  ToDo = 'To-Do',
  InFlight = 'In Flight',
  OnHold = 'On Hold',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
}

export enum InitiativeDeliveryStatus {
  OnTrack = 'On Track',
  Delayed = 'Delayed',
}

export enum InitiativeFundingMechanism {
  BusinessAsUsual = 'Business-As-Usual (BAU)',
  FundingRequired = 'Funding required',
  FundingSecured = 'Funding secured',
}

export enum StewardsOpinion {
  Pending = 'Pending',
  Challenged = 'Challenged',
  Concurred = 'Concurred',
}

export enum ConceptualSourceTypes {
  Documentation = 'Documentation',
  Interview = 'Interview',
}

export enum ScenarioVisibility {
  Public = 'public',
  Private = 'private',
}

export const ReadableScenarioVisibility = {
  [ScenarioVisibility.Public]: 'Public',
  [ScenarioVisibility.Private]: 'Private',
};

export const SubLayersWithoutValidStatus: SubLayerTypes[] = [
  InitiativesSubLayerTypes.ChangeInitiatives,
  SensitiveElementsSubLayerTypes.AssetCharacteristics,
  AssociatedRisksSubLayerTypes.Risks,
];
