import React, { FC } from 'react';
import CriteriaField from '@components/EntityDrawers/components/CiaInputsGroup/CriteriaField';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { Stack, Typography } from '@mui/material';
import { CIASettingsCriteriaType } from '@store/services/projects/types';

type Props = {
  title: string;
  fields: CIASettingsCriteriaType[];
  criteriaKey:
    | CommonKeys.Assurance
    | CommonKeys.Coverage
    | CommonKeys.Applicability;
};

const CriteriaBlock: FC<Props> = ({ title, fields, criteriaKey }) => {
  return (
    <Stack gap={4}>
      <Typography variant="h4">{title}</Typography>

      {fields.map((field) => (
        <CriteriaField
          key={field.name}
          field={field}
          nameKey={`${criteriaKey}.${field.name}`}
        />
      ))}
    </Stack>
  );
};

export default CriteriaBlock;
