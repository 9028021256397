import { SetStateAction, useMemo, useState } from 'react';
import {
  EntityReviewStatus,
  EntityValidationStatus,
  ReadableEntityReviewStatus,
  ReadableEntityValidationStatus,
} from '@constants/canvas/layers';
import { filtersSubject } from '@context/ProjectFilters/FiltersSubject';
import { CiaFilters } from '@context/ProjectFilters/types';

const validationStatusOptions = [
  EntityValidationStatus.Validated,
  EntityValidationStatus.Draft,
].map((status) => ({
  label: ReadableEntityValidationStatus[status],
  value: status,
}));

const reviewStatusOptions = [EntityReviewStatus.ReviewRequired].map(
  (status) => ({
    label: ReadableEntityReviewStatus[status],
    value: status,
  }),
);

export const useFilters = () => {
  const [validationStatus, setValidationStatus] = useState([]);
  const [reviewStatus, setReviewStatus] = useState([]);
  const [ciaFilters, setCiaFilters] = useState<CiaFilters>({
    assurance_level: {},
    coverage: {},
    applicability: {},
  });

  const validationStatusSelectedFilters = useMemo(
    () =>
      validationStatus.map((status) => ({
        label: status,
      })),
    [validationStatus],
  );

  const reviewStatusesSelectedFilters = useMemo(
    () =>
      reviewStatus.map((status) => ({
        label: status,
      })),
    [reviewStatus],
  );

  const ciaSelectedFilters = useMemo(
    () =>
      Object.values(ciaFilters).reduce<string[]>((selected, fields) => {
        return [...selected, ...(Object.values(fields).flat() as string[])];
      }, []),
    [ciaFilters],
  );

  const selectedFilters = useMemo(
    () => [
      ...validationStatusSelectedFilters.map((status) => status.label),
      ...reviewStatusesSelectedFilters.map((status) => status.label),
      ...ciaSelectedFilters,
    ],
    [
      validationStatusSelectedFilters,
      reviewStatusesSelectedFilters,
      ciaSelectedFilters,
    ],
  );

  const handleSetValidationStatus = (validationStatus: string[]) => {
    setValidationStatus(validationStatus as never[]);
    filtersSubject.next({ ...filtersSubject.value, validationStatus });
  };

  const handleSetReviewStatus = (reviewStatus: string[]) => {
    setReviewStatus(reviewStatus as never[]);
    filtersSubject.next({ ...filtersSubject.value, reviewStatus });
  };

  const handleSetCiaFilters = (newState: SetStateAction<CiaFilters>) => {
    setCiaFilters(newState);

    const cia =
      typeof newState === 'function' ? newState(ciaFilters) : newState;

    filtersSubject.next({ ...filtersSubject.value, cia });
  };

  const handleClearAllFilters = () => {
    handleSetValidationStatus([]);

    handleSetReviewStatus([]);

    handleSetCiaFilters({
      assurance_level: {},
      coverage: {},
      applicability: {},
    });
  };

  return {
    validationStatusOptions,
    reviewStatusOptions,
    ciaFilters,
    validationStatus,
    reviewStatus,
    setValidationStatus: handleSetValidationStatus,
    setReviewStatus: handleSetReviewStatus,
    setCiaFilters: handleSetCiaFilters,
    selectedFilters,
    handleClearAllFilters,
  };
};
