import { QueryTags } from '@store/services/tags';
import { startLoading, stopLoading } from '@store/slices/uiSlice';

export const successTags = (tags: QueryTags[]) => (_: any, err: unknown) =>
  err ? [] : tags;

export const onLoading = (
  // eslint-disable-next-line default-param-last
  { noSpinner }: any = {},
  { dispatch, queryFulfilled, requestId }: any,
) => {
  if (noSpinner) return;

  dispatch(startLoading(requestId));
  return queryFulfilled.finally(() => dispatch(stopLoading(requestId)));
};
