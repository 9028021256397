import React from 'react';
import ReactFlow, { Background } from 'reactflow';
import { CustomControls } from '@components/MainStage/CustomControls';
import { CustomEdge } from '@components/MainStage/CustomEdge';
import { CustomNode } from '@components/MainStage/CustomNode';
import useTranslateExtent from '@components/MainStage/hooks/stage/useTranslateExtent';
import useFilteredCanvas from '@components/MainStage/hooks/useFilteredCanvas';
import { LayerNode } from '@components/MainStage/LayerNode';
import { BlockBackground } from '@components/MainStage/MainStage.styled';
import { NewNode } from '@components/MainStage/NewNode';
import { SubLayerNode } from '@components/MainStage/SubLayerNode';
import {
  canvasStageStyles,
  INITIAL_VIEWPORT,
  MAX_ZOOM,
  MIN_ZOOM,
} from '@constants/canvas/general';
import { ReactFlowId } from '@constants/entities/reports';
import { NodesDataProvider } from '@context/NodesDataContext';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import useCanvasZoomController from '@hooks/canvas/useCanvasZoomController';
import { Box } from '@mui/material';
import { InsightStageStyled } from '@views/Insights/Insights.styled';
import useEdgeClick from '@views/Insights/Stage/hooks/stage/useEdgeClick';
import useNodeClick from '@views/Insights/Stage/hooks/stage/useNodeClick';
import usePaneClick from '@views/Insights/Stage/hooks/stage/usePaneClick';
import { Tabs } from '@views/Insights/types';

const nodeTypes = {
  customNode: CustomNode,
  newNode: NewNode,
  layerNode: LayerNode,
  subLayerNode: SubLayerNode,
};

const edgeTypes = {
  customEdge: CustomEdge,
};

export const Stage = () => {
  const { handleCloseDrawer, hasChanges, insightsTab: type } = useProject();
  const { nodes, edges } = useFilteredCanvas();
  const { zoom } = useCanvasZoom();
  const onPaneClick = usePaneClick();
  const onEdgeClick = useEdgeClick(onPaneClick);
  const onNodeClick = useNodeClick(onPaneClick);
  const onNodeDragStart = () => {};
  const onNodeDragStop = () => {};

  const onBlockBackClick = () => {
    if (type === Tabs.Dependency) {
      handleCloseDrawer();
    } else if (type === Tabs.FocusOn) {
      handleCloseDrawer(undefined, false);
    }
  };

  useCanvasZoomController();
  const translateExtent = useTranslateExtent();

  return (
    <Box id="canvas" position="relative">
      {hasChanges && <BlockBackground onClick={onBlockBackClick} />}

      <InsightStageStyled>
        <NodesDataProvider>
          <ReactFlow
            id={ReactFlowId}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            elevateNodesOnSelect
            selectNodesOnDrag={false}
            onPaneClick={onPaneClick}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            onNodeDragStart={onNodeDragStart}
            onNodeDragStop={onNodeDragStop}
            style={canvasStageStyles}
            multiSelectionKeyCode={null}
            deleteKeyCode={null}
            translateExtent={translateExtent}
            zoomOnDoubleClick={false}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            zoomOnPinch={false}
            defaultViewport={INITIAL_VIEWPORT(zoom)}
          >
            <Background />
            <CustomControls />
          </ReactFlow>
        </NodesDataProvider>
      </InsightStageStyled>
    </Box>
  );
};
