import { themePalette } from '@theme/muiTheme';
import { InfoCircleEmptyIcon } from '@utils/iconsDefs';
import styled from 'styled-components';

type InsightProps = {
  $isExpanded: boolean;
  $isHidden: boolean;
};

const hiddenStyles = 'width: 0px; height: 0; overflow: hidden; padding: 0;';

export const InsightStageStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100vh - 180px); // 180 - height of static header
  z-index: 5;

  .react-flow__attribution {
    display: none;
  }

  .react-flow__edge-interaction {
    pointer-events: none;
  }

  .react-flow__edge-path {
    pointer-events: all;

    &:hover {
      stroke: ${themePalette.grey[800]} !important; // works only with important
    }
  }

  .react-flow__handle.connectionindicator {
    pointer-events: none;
  }
`;

export const INSIGHT_DRAWER_WIDTH = 354;
export const INSIGHT_DRAWER_COLLAPSED_WIDTH = 60;

export const InsightsStyled = styled('div')<InsightProps>`
  position: relative;
  width: ${INSIGHT_DRAWER_COLLAPSED_WIDTH}px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${themePalette.white};
  border-left: 1px solid ${themePalette.grey[500]};

  ${({ $isExpanded, $isHidden }) => {
    let styles = '';

    if ($isExpanded) {
      styles += `width: ${INSIGHT_DRAWER_WIDTH}px;`;
    }

    if ($isHidden) {
      styles += hiddenStyles;
    }

    return styles;
  }}
`;

export const InsightTabStyled = styled('div')<{ $isHidden: boolean }>`
  overflow: auto;
  height: 100%;
  width: 100%;
  scrollbar-gutter: stable;

  ${({ $isHidden }) => $isHidden && hiddenStyles}
`;

export const InsightBodyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const Header = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  background: ${themePalette.white};
`;

export const CollapsedHeader = styled('div')<{ $isHidden: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${themePalette.grey[500]};

  ${({ $isHidden }) => $isHidden && hiddenStyles}
`;

export const InsightTabsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 24px 24px 24px;
`;

export const InsightTab = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${themePalette.grey[500]};
  border-radius: 12px;
  margin-bottom: 24px;

  &:hover {
    border: 1px solid ${themePalette.grey[700]};
    cursor: pointer;
  }
`;

export const InsightHeaderStyled = styled('div')`
  position: relative;
  display: flex;
  margin-bottom: 12px;
`;

export const ArrowIconWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  cursor: pointer;
`;

export const ExpandButton = styled('button')`
  position: absolute;
  top: 50%;
  left: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${themePalette.white};
  transform: translateX(-50%);
  cursor: pointer;
  border: none;
  box-shadow:
    0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid ${themePalette.grey[700]};
  border-radius: 4px;
  cursor: pointer;
`;

export const ListEntityWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  overflow: hidden;
  gap: 8px;
`;

export const InfoIcon = styled(InfoCircleEmptyIcon)`
  min-width: 20px;
  height: 20px;

  fill: ${themePalette.grey[700]};

  &:hover {
    fill: ${themePalette.grey[900]};
  }
`;

export const EmptyStateWrapper = styled('div')`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
