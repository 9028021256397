import React, { ComponentProps, FC, SVGProps, useMemo } from 'react';
import {
  FileData,
  SuccessfullyUploadedStats,
} from '@components/UploadModal/types';
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { themePalette } from '@theme/muiTheme';
import { formatFileSize } from '@utils/helpers';
import { DocumentDisabledIcon, TrashIcon, WarningIcon } from '@utils/iconsDefs';

import {
  ErrorBoxStyled,
  UploadStatusBoxStyled,
} from './UploadFileStatus.styled';
import { UploadFileStatusSuccess } from './UploadFIleStatusSuccess';

export type ChipNames = {
  error?: string;
  loading?: string;
  uploaded?: string;
};

export interface UploadFileStatusProps {
  fileData: FileData;
  isLoading: boolean;
  uploadError: string;
  stats?: SuccessfullyUploadedStats[];
  status: QueryStatus;
  Icon?: FC<SVGProps<any>>;
  onDelete?: () => void;
  chipNames?: ChipNames;
  sx?: SxProps;
}

const defaultChipNames: ChipNames = {
  error: 'Failed',
  loading: 'Uploading...',
  uploaded: 'Uploaded',
};

export const UploadFileStatus: FC<UploadFileStatusProps> = ({
  fileData,
  isLoading,
  uploadError,
  stats = [],
  status,
  Icon = DocumentDisabledIcon,
  onDelete,
  chipNames = defaultChipNames,
  sx,
}) => {
  const statusChipData = useMemo<{
    label: string;
    variant: ComponentProps<typeof Chip>['variant'];
  } | null>(() => {
    const mergedChipNames = { ...defaultChipNames, ...chipNames };

    if (isLoading) {
      return { label: mergedChipNames.loading ?? '', variant: 'grey' };
    }

    if (uploadError) {
      return { label: mergedChipNames.error ?? '', variant: 'red' };
    }

    if (status === QueryStatus.fulfilled) {
      return { label: mergedChipNames.uploaded ?? '', variant: 'green' };
    }

    return null;
  }, [isLoading, uploadError, chipNames]);

  return (
    <UploadStatusBoxStyled sx={sx}>
      <Stack direction="row" alignItems="flex-start" gap={4}>
        <Icon style={{ marginTop: 2, minWidth: 20 }} />

        <Stack direction="column" gap={2} justifyContent="center">
          <Typography variant="h4">{fileData.name}</Typography>

          <Stack direction="row" gap={2}>
            <Typography variant="body1" color="grey.800">
              {formatFileSize(fileData.size)}
            </Typography>

            {statusChipData && (
              <Chip
                variant={statusChipData.variant}
                label={statusChipData.label}
              />
            )}
          </Stack>
        </Stack>

        {onDelete && !!fileData.name.trim() && status !== 'fulfilled' && (
          <>
            <Box flex={1} />

            <IconButton onClick={onDelete} size="initial">
              <TrashIcon width={24} height={24} style={{ minWidth: 24 }} />
            </IconButton>
          </>
        )}
      </Stack>

      {isLoading && !uploadError && <LinearProgress />}

      {status === QueryStatus.fulfilled && !uploadError && (
        <LinearProgress variant="determinate" value={100} />
      )}

      {uploadError && (
        <>
          <LinearProgress variant="determinate" value={0} />

          <ErrorBoxStyled>
            <WarningIcon fill={themePalette.system.error} />
            <Typography variant="body2" color="grey.900" sx={{ ml: 2 }}>
              {uploadError}
            </Typography>
          </ErrorBoxStyled>
        </>
      )}

      {stats.map((successStats) => (
        <UploadFileStatusSuccess key={successStats.id} {...successStats} />
      ))}
    </UploadStatusBoxStyled>
  );
};
