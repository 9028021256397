import { useMemo } from 'react';
import { InsightKeys } from '@constants/entities/insights';
import useProjectData from '@hooks/useProjectData';
import { QuestionnaireStatus } from '@store/services/projects/types';

const useCiaData = () => {
  const [{ subscription, questionnaire }, { isLoading: isProjectLoading }] =
    useProjectData();

  const isCiaActivated = useMemo(() => {
    if (!subscription || !questionnaire) return false;

    const isCiaInInsightsList = subscription.insights.includes(InsightKeys.CIA);

    const isQuestionnaireUploaded =
      questionnaire.status === QuestionnaireStatus.Active;

    return isCiaInInsightsList && isQuestionnaireUploaded;
  }, [subscription, questionnaire]);

  return {
    isCiaActivated,
    questionnaire,
    isLoading: isProjectLoading,
  };
};

export default useCiaData;
