import { useCallback, useRef, useState } from 'react';
import { FileData } from '@components/UploadModal/types';
import { useDebounce } from '@hooks/useDebounce';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { type MutationDefinition, QueryStatus } from '@reduxjs/toolkit/query';
import { formatNumber, parseErrorResponse } from '@utils/helpers';
import { defaultMaxFileSize } from '@views/Operations/constants';

type Options<ResponseType> = {
  onSuccess?: (res: ResponseType | { success: true }) => void;
  maxFileSize?: number;
  payload?: Record<string, any>;
  controlledUpload?: boolean;
};

const useFileUpload = <ResponseType>(
  useMutation: UseMutation<
    MutationDefinition<{ file: File } & any, any, any, ResponseType>
  >,
  {
    onSuccess,
    maxFileSize = defaultMaxFileSize,
    payload,
    controlledUpload,
  }: Options<ResponseType> = {},
) => {
  const [uploadFile, { isLoading, status, reset }] = useMutation();

  const [uploadError, setUploadError] = useState('');
  const [uploadResult, setUploadResult] = useState<
    ResponseType | { success: true } | null
  >(null);

  const [fileData, setFileData] = useState<FileData>({
    name: '',
    size: 0,
  });

  const fileRef = useRef<File | null>(null);

  const startFileUploading = async (extraPayload?: any) => {
    if (!fileRef.current) return;

    try {
      const res = await uploadFile({
        file: fileRef.current,
        ...payload,
        ...extraPayload,
      }).unwrap();
      setUploadResult(res ?? { success: true });
      onSuccess?.(res ?? { success: true });
    } catch (error) {
      setUploadError(parseErrorResponse(error));
    }
  };

  const onDrop = useCallback(
    async ([file]: File[]) => {
      if (file.size > maxFileSize) {
        setFileData({ name: file.name, size: file.size });
        setUploadError(
          `File exceeds the maximum allowed size of ${formatNumber(
            maxFileSize,
            true,
          )}.`,
        );
        return;
      }

      setFileData({ name: file.name, size: file.size });
      setUploadError('');

      fileRef.current = file;

      if (!controlledUpload) {
        startFileUploading();
      }
    },
    [controlledUpload],
  );

  const isUploadDisabled = controlledUpload
    ? !!fileData.name
    : status === QueryStatus.fulfilled;

  // use debounce to avoid blinking of <UplaodFileStatus />
  const showFileStatus = useDebounce(
    isLoading || uploadError || status === QueryStatus.fulfilled,
    50,
  );

  const resetUploadState = () => {
    setFileData({ name: '', size: 0 });
    reset();
    setUploadError('');
    setUploadResult(null);
  };

  return {
    uploadError,
    uploadResult,
    fileData,
    onDrop,
    startFileUploading,
    isUploadDisabled,
    showFileStatus,
    isLoading,
    status,
    resetUploadState,
  };
};

export default useFileUpload;
