import { EnterpriseRoles } from '@constants/entities/user';

export const IndexKey = '[index]';

/*
  Enterprise admin role licences count is included in ClientModeller licences
 */
export type RolesCount = Omit<
  Record<(typeof EnterpriseRoles)[number], number>,
  'enterprise-admin'
>;
