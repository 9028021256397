import React, { FC } from 'react';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { ISelectOption } from '@constants/entities/ui';
import useToggle from '@hooks/useToggle';
import {
  Box,
  ButtonBase,
  Checkbox,
  Collapse,
  MenuItem,
  Stack,
} from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

export type FilterSelectorProps<T = string | number> = {
  title: string;
  values: T[];
  options: ISelectOption[];
  onChange: (values: T[]) => void;
  expandByDefault?: boolean;
};

export type FilterSelectorWithKeyProps<T = string | number> =
  FilterSelectorProps<T> & { key: string };

const FilterSelector: FC<FilterSelectorProps> = ({
  title,
  options,
  values,
  onChange,
  expandByDefault = false,
}) => {
  const [isExpanded, { toggle }] = useToggle(expandByDefault);

  const handleChange = (value: string | number) => () => {
    if (values.includes(value)) {
      return onChange(values.filter((v) => v !== value));
    }

    return onChange([...values, value]);
  };

  const arrowFill = () => {
    if (isExpanded || values.length > 0) {
      return themePalette.grey[1000];
    }

    return themePalette.grey[900];
  };

  return (
    <>
      <ButtonBase sx={{ width: '100%', p: '8px 12px' }} onClick={toggle}>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TypographyWithElipsis variant="body1">{title}</TypographyWithElipsis>

          <DropdownIcon
            fill={arrowFill()}
            style={{
              transform: isExpanded ? 'rotate(180deg)' : '',
              minWidth: 20,
            }}
          />
        </Stack>
      </ButtonBase>

      <Box sx={{ '& .MuiCollapse-root': { minHeight: 'initial !important' } }}>
        <Collapse in={isExpanded} timeout="auto">
          {options.map(({ label, value }) => {
            const isChecked = values.includes(value);

            return (
              <MenuItem key={title + value}>
                <Checkbox checked={isChecked} onChange={handleChange(value)} />

                <TypographyWithElipsis
                  variant="body1"
                  color={isChecked ? 'grey.1000' : 'grey.900'}
                  tooltipPlacement="bottom"
                >
                  {label}
                </TypographyWithElipsis>
              </MenuItem>
            );
          })}
        </Collapse>
      </Box>
    </>
  );
};

export default FilterSelector;
