import React, { FC } from 'react';
import CiaInputsGroup from '@components/EntityDrawers/components/CiaInputsGroup';
import DesignEffectivenessDropdown from '@components/EntityDrawers/components/inputs/DesignEffectivenessDropdown';
import OperationalEffectivenessDropdown from '@components/EntityDrawers/components/inputs/OperationalEffectivenessDropdown';
import OperationalModelDropdown from '@components/EntityDrawers/components/inputs/OperationalModelDropdown';
import OperationalStatusRadioGroup from '@components/EntityDrawers/components/inputs/OperationalStatusRadioGroup';
import TimelineDates from '@components/EntityDrawers/components/inputs/TimelineDates';
import AuditFindingGroup from '@components/EntityDrawers/drawers/Component/inputs/AuditFindingGroup';

const Advanced: FC = () => {
  return (
    <>
      <OperationalStatusRadioGroup />
      <TimelineDates />
      <AuditFindingGroup />
      <CiaInputsGroup />
      <DesignEffectivenessDropdown />
      <OperationalEffectivenessDropdown />
      <OperationalModelDropdown />
    </>
  );
};

export default Advanced;
